<template>
  <ErrorView is-page/>
</template>

<script>
  import ErrorView from '../components/ErrorView'

  export default {
    components: {
      ErrorView
    }
  }
</script>

<style>

</style>
